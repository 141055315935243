<template>
    <div>

        <div class="block">
            <!--

                TODO: create form component to wrap this
                TODO: button component with loading states and icons
                TODO: validation for inpts, validation for forms
                TODO: form self-submit behaviour

            -->


            <form class="uk-form-horizontal uk-margin uk-margin-top">

                <!--       class="test-class-prop"
                                       id="test-id"-->


                <form-input

                        label="Test label1 "
                        placeholder="test placeholder"
                        name="test"
                        v-model="testData"
                        class="test"
                        id="testId"
                        size="large"
                        iconEnd="check"
                        data-bogle="toggle"
                        @focus="testHandler"
                ></form-input>
                <form-input

                        label="Test label no model "
                        placeholder="test placeholder"
                        name="test33"
                        icon="user"
                        class="test"
                        id="testId"
                        data-bogle="toggle"
                        @focus="testHandler"
                ></form-input>
                <form-input

                        label="Test label no model "
                        placeholder="test placeholder"
                        name="test33"
                        icon="user"
                        class="test"
                        size="small"
                        data-bogle="toggle"
                        @focus="testHandler"
                ></form-input>
                <form-input

                        label="Test label 2 "
                        placeholder="test placeholder"
                        name="test"
                        type="textarea"
                        v-model="testData2"
                ></form-input>
                <form-input

                        label="Test label 3"
                        placeholder="test placeholder"
                        name="test"
                        type="select"
                        :list="testData3OptionsList"
                        v-model="testData3"
                ></form-input>
                <form-input
                        label="Test label checkbox"
                        placeholder="test placeholder"
                        name="test"
                        type="checkbox"
                        v-model="testData4"
                ></form-input>
                <form-input
                        label="Test label radio"
                        placeholder="test placeholder"
                        name="test"
                        type="radio"
                        :list="testData5OptionsList"
                        v-model="testData5"
                        :radioStacked="true"
                ></form-input>

                <form-button
                        class="uk-margin-small-right"

                        data-whatever="bogle"
                        icon="check"
                        size="default"
                        :loading="true"
                >text text</form-button>
                <form-button
                        class="uk-margin-small-right"
                        type="default"
                        data-whatever="bogle"
                        icon="check"
                        size="default"
                >Reset</form-button>
                <form-button
                        class="uk-margin-small-right"
                        data-whatever="bogle"
                        icon="check"
                        size="default"



                >Submit</form-button>
            </form>

        </div>
        <br><br>
        <div class="block">
            va1 {{testData}}<br>
            va2{{testData2}}<br>
            val3 {{testData3}}<br>
            val4 {{testData4}}<br>
            val5 {{testData5}}<br>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {};
        }

    }
</script>

<style scoped lang="scss">

</style>
